<template>
  <div>
    <div id="displayCard" v-if="!loading">
      <div class="addArtwork">
        <div class="ma-0 pa-0 socialProfileCard">
          <div class="socialGrid">
            <div class="userInfoPanel" style="padding-top: 0px">
              <user-profile-card :userid="userid" :key="userid" />
              <div style="flex: 1"></div>
            </div>

            <div class="gallery userGallery" style="padding-top: 0px">
              <v-card class="bazaarCard">
                <bazaar-gallery :items="items" :noSmallScaling="true" :loading="loading" :hoverElement="true" />
              </v-card>
              <div style="flex: 1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-center justify-center" style="min-height: 60vh" v-else>
      <v-progress-circular indeterminate size="60" color="primary" v-if="loading"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import BazaarGallery from "../components/Common/BazaarGallery.vue";
import config from "../config";
import Platform from "../layouts/Platform";

import { displayWorksPage } from "../services/workService";

import UserProfileCard from "../components/Common/UserProfileCard.vue";
export default {
  components: { BazaarGallery, UserProfileCard },
  name: "UserBazaar",
  props: {
    userid: {
      type: String
    }
  },

  async created() {
    this.$emit(`update:layout`, Platform);
    this.$store.commit("setBackDestination", {
      show: true,
      path: "/platform/bazaar",
      name: this.$t("titleBazaar"),
      callback: null
    });
    this.loading = true;

    this.searchParams["creator-search"] = this.$route.params.userid;
    const result = await displayWorksPage(this.$axios, config, this.searchParams);
    if (result.status === 200) {
      this.items = result.data;
      // if (this.items.length === 0) {
      //   this.$router.push("/*").catch((e) => e);
      // }
    } else {
      this.loading = false;
      this.$router.push("/*").catch((e) => e);
    }

    this.loading = false;
  },
  methods: {},
  data: () => ({
    items: [],
    loading: false,
    profile: null,

    searchParams: {
      "items-per-page": null,
      "page-number": null,
      "work-info-search": null,
      "unmanaged-info-search": null,
      "sorting-by-time": true,
      "sorting-by-like": false
    }
  })
};
</script>

<style></style>
